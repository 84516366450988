/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import axios from "axios/axios";
import { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  Alert,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import Variants from "./Variants";
import Images from "./Images";

const AddEditProduct = ({
  product,
  setProduct,
  selectedProduct,
  editing,
  categories,
  subCategories,
  catBol,
  subCatBol,
  setCatCol,
  setSubCatCol,
  setReload,
  removeProductSelection,
  setEditing,
  setSelectedProduct,
  show,
  setShow,
}) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [Error, setError] = useState("");

  const handleAdd = () => {
    var fdata = new FormData();
    // Images (Required)
    if (files[0]) {
      for (let one of files) {
        fdata.append("files", one);
        setError(null);
      }
    } else return setError("Please Choose at least one image");
    // Name (Required)
    if (product.name) fdata.append("name", product.name);
    else return setError("Please enter name");
    // Name Ar (Required)
    if (product.nameAr) fdata.append("nameAr", product.nameAr);
    else return setError("Please enter name in Arabic");
    // Subcategory (Required)
    if (subCategory) fdata.append("subCategory", subCategory.value);
    else return setError("Please choose sub-category");
    // Description (Optional)
    if (product.description) fdata.append("description", product.description);
    // Description (Optional)
    if (product.descriptionAr)
      fdata.append("descriptionAr", product.descriptionAr);
    // Variants (at least one containing price and stock)
    if (
      !product.variants ||
      !(
        Array.isArray(product.variants) &&
        product.variants.length > 0 &&
        product.variants[0].stock &&
        product.variants[0].price
      )
    )
      return setError("At least one variant must be specified");
    product.variants.forEach((vr, ind) => {
      fdata.append(`variants[${ind}][price]`, vr.price);
      fdata.append(`variants[${ind}][stock]`, vr.stock);
      if (vr.size) fdata.append(`variants[${ind}][size]`, vr.size);
      if (vr.color && vr.color._id)
        fdata.append(`variants[${ind}][color]`, vr.color._id);
    });
    if (!Error || Error === "") {
      setLoadingSubmit(true);
      axios
        .post("products/addProduct", fdata, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then(() => {
          setSelectedFiles([]);
          setFiles([]);
          setReload((prev) => !prev);
          setProduct({
            name: "",
            description: "",
            nameAr: "",
            descriptionAr: "",
            images: [],
            price: 0,
            stock: 0,
            aroma: 0,
            balance: 0,
            body: 0,
            intensity: 0,
            sweetness: 0,
            packageType: "colored",
            type: "general",
            size: "",
            pieces: "",
            color: "",
            variants: [],
          });
        })
        .catch((err) => {
          setError(err.response.data.message);
        })
        .finally(() => setLoadingSubmit(false));
    }
  };

  const handleEdit = () => {
    setLoadingSubmit(true);
    let edit = false;
    var fdata = new FormData();
    if (files.length > 0) {
      for (let one of files) {
        fdata.append("files", one);
      }
      edit = true;
    }
    if (product.name != selectedProduct.name && product.name != "") {
      fdata.append("name", product.name);
      edit = true;
    }
    if (product.nameAr != selectedProduct.nameAr && product.nameAr != "") {
      fdata.append("nameAr", product.nameAr);
      edit = true;
    }
    if (product.description)
      fdata.append(
        "description",
        product.description ? product.description : ""
      );
    if (product.descriptionAr) {
      fdata.append(
        "descriptionAr",
        product.descriptionAr ? product.descriptionAr : ""
      );
    }
    if (product.variants.length > 0) {
      product.variants.forEach((vr, ind) => {
        fdata.append(`variants[${ind}][price]`, vr.price);
        fdata.append(`variants[${ind}][stock]`, vr.stock);
        if (vr.size) fdata.append(`variants[${ind}][size]`, vr.size);
        if (vr.color && vr.color._id)
          fdata.append(`variants[${ind}][color]`, vr.color._id);
      });
    }
    if (subCategory && subCategory.value != selectedProduct.subCategory._id) {
      fdata.append("subCategory", subCategory.value);
      edit = true;
    }
    axios
      .patch(`products/updateProduct/${selectedProduct._id}`, fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then(() => {
        setEditing(false);
        setSelectedProduct(null);
        setSelectedFiles([]);
        setFiles([]);
        setLoadingSubmit(false);
        setReload((prev) => !prev);
        setProduct({
          name: "",
          description: "",
          nameAr: "",
          descriptionAr: "",
          images: [],
          price: 0,
          stock: 0,
          aroma: 0,
          balance: 0,
          body: 0,
          intensity: 0,
          type: "",
          sweetness: 0,
          packageType: "",
          size: "",
          variants: [],
          pieces: "",
          color: "",
        });
      })
      .catch((e) => {
        setError(e.response.data.message);
        setLoadingSubmit(false);
      });

    setLoadingSubmit(false);
  };

  // const handleDelete = (id) => {
  //   let confirm = window.confirm(
  //     "You are about to delete this product, do you wish to continue?"
  //   );
  //   if (confirm) {
  //     axios
  //       .delete(`products/deleteproduct/${id}`, {
  //         headers: {
  //           Authorization: sessionStorage.getItem("token"),
  //         },
  //       })
  //       .then((res) => {
  //         setProduct({
  //           titleEnglish: "",
  //           descriptionEnglish: "",
  //           bodyEnglish: "",
  //           titleArabic: "",
  //           descriptionArabic: "",
  //           bodyArabic: "",
  //           img: null,
  //         });
  //         setSelectedProduct(null);
  //         setReload((prev) => !prev);
  //       })
  //       .catch((e) => {
  //       });
  //   }
  // };

  const handleSubmit = () => {
    if (selectedProduct) {
      handleEdit();
    } else {
      handleAdd();
    }
  };

  return (
    <Form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {/* Name */}
      <Row>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label htmlFor="name" style={{ color: "#5B626B" }}>
              Name*
            </Label>
            {editing || !selectedProduct ? (
              <>
                <OutlinedInput
                  style={{ height: "6vh" }}
                  id="name"
                  type="text"
                  placeholder="Name"
                  value={product.name}
                  className="form-control"
                  onChange={(e) => {
                    setProduct({
                      ...product,
                      name: e.target.value,
                    });
                  }}
                />
              </>
            ) : (
              <p style={{ color: "#5B626B" }}>
                {selectedProduct.name ? selectedProduct.name : ""}
              </p>
            )}
          </FormGroup>
        </Col>
        <Col xs={12} md={6} dir="rtl">
          <FormGroup className="mb-3">
            <Label htmlFor="nameAr" style={{ color: "#5B626B" }}>
              الاسم*
            </Label>
            {editing || !selectedProduct ? (
              <OutlinedInput
                id="nameAr"
                type="text"
                placeholder="الاسم"
                value={product.nameAr}
                className="form-control"
                style={{ height: "6vh" }}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    nameAr: e.target.value,
                  });
                }}
              />
            ) : (
              <p style={{ color: "#5B626B" }}>
                {selectedProduct.nameAr ? selectedProduct.nameAr : ""}
              </p>
            )}
          </FormGroup>
        </Col>
      </Row>
      {/* Desc */}
      <Row>
        <Col xs={12} md={6}>
          <FormGroup className=" mb-3">
            <Label htmlFor="description" style={{ color: "#5B626B" }}>
              Description
            </Label>
            {editing || !selectedProduct ? (
              <OutlinedInput
                multiline
                id="description"
                type="textarea"
                placeholder="Description"
                className="form-control"
                value={product.description}
                style={{ minHeight: "10vh" }}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    description: e.target.value,
                  });
                }}
              />
            ) : (
              <p style={{ color: "#5B626B" }}>
                {selectedProduct.description ? selectedProduct.description : ""}
              </p>
            )}
          </FormGroup>
        </Col>
        <Col xs={12} md={6} dir="rtl">
          <FormGroup>
            <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
              الشرح
            </Label>
            {editing || !selectedProduct ? (
              <OutlinedInput
                multiline
                style={{ minHeight: "10vh" }}
                id="descriptionAr"
                className="form-control"
                type="textarea"
                placeholder="Description in Arabic"
                value={product.descriptionAr}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    descriptionAr: e.target.value,
                  });
                }}
              />
            ) : (
              <p style={{ color: "#5B626B" }}>
                {selectedProduct.description ? selectedProduct.description : ""}
              </p>
            )}
          </FormGroup>
        </Col>
      </Row>
      {/* Category & Sub-category */}
      <Row>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label htmlFor="category" style={{ color: "#5B626B" }}>
              Category*
            </Label>
            {editing || !selectedProduct ? (
              <Select
                id="category"
                value={
                  editing
                    ? catBol
                      ? product._id
                        ? {
                            label: product.subCategory.category.name,
                            value: product.subCategory.category._id,
                          }
                        : ""
                      : category
                    : category
                }
                onChange={(value) => {
                  setCategory(value);
                  setCatCol(false);
                  setSubCategory(null);
                }}
                options={categories.map((one) => {
                  return {
                    label: one.name,
                    value: one._id,
                  };
                })}
                classNamePrefix="select2-selection"
              />
            ) : (
              <p style={{ color: "#5B626B" }}>
                {selectedProduct.subCategory.category
                  ? selectedProduct.subCategory.category.name
                  : ""}
              </p>
            )}
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label htmlFor="subcategory" style={{ color: "#5B626B" }}>
              Sub Category*
            </Label>
            {editing || !selectedProduct ? (
              <Select
                id="subcategory"
                value={
                  editing
                    ? subCatBol
                      ? !subCategory
                        ? product._id
                          ? {
                              label: product.subCategory.name,
                              value: product.subCategory._id,
                            }
                          : ""
                        : subCategory
                      : subCategory
                    : subCategory
                }
                onChange={(value) => {
                  setSubCategory(value);
                  setSubCatCol(false);
                }}
                options={subCategories
                  .filter((one) =>
                    catBol
                      ? one.category === product._id &&
                        product.subCategory.category[0]._id
                      : category && one.category === category.value
                  )
                  .map((one) => {
                    return {
                      label: one.name,
                      value: one._id,
                    };
                  })}
                classNamePrefix="select2-selection"
              />
            ) : (
              <p style={{ color: "#5B626B" }}>
                {selectedProduct.subCategory.name
                  ? selectedProduct.subCategory.name
                  : ""}
              </p>
            )}
          </FormGroup>
        </Col>
      </Row>
      {/* Variants */}
      <Variants
        product={product}
        setProduct={setProduct}
        editing={editing}
        show={show}
        setShow={setShow}
      />
      {/* Images */}
      <Images
        editing={editing}
        setEditing={setEditing}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        files={files}
        setFiles={setFiles}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        setProduct={setProduct}
        loadingSubmit={loadingSubmit}
        setReload={setReload}
      />
      {/* Error */}
      {Error && <Alert color="danger">{Error}</Alert>}
      {editing || !selectedProduct ? (
        <div className="col-12">
          {/* Close editing */}
          {editing && (
            <button
              className="btn btn-secondary"
              style={{ marginRight: "1vw" }}
              onClick={removeProductSelection}
            >
              <i className="ion ion-md-close"></i>
            </button>
          )}
          {/* Submit edit */}
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "submit"}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      ) : (
        // Enable editing
        <div className="col-12">
          <button
            className="btn btn-secondary"
            style={{ marginRight: "1vw" }}
            onClick={removeProductSelection}
          >
            <i className="fas fa-close"></i>
          </button>
          {sessionStorage.getItem("admin") && (
            <button
              className="btn btn-primary"
              style={{ marginRight: "1vw" }}
              onClick={(e) => {
                e.preventDefault();
                setEditing(true);
                setShow(false);
              }}
            >
              <i className="fas fa-edit"></i>
            </button>
          )}
          {/* Delete product */}
          {/* <button
            className="btn btn-danger"
            onClick={(e) => {
              e.preventDefault();
              handleDelete(selectedProduct._id);
            }}
          >
            <i className="fas fa-trash"></i>
          </button> */}
        </div>
      )}
    </Form>
  );
};

export default AddEditProduct;
