import Dropzone from "react-dropzone";
import { Col, FormGroup, Label, Row } from "reactstrap";

const Images = ({
  editing,
  selectedProduct,
  files,
  setFiles,
  selectedFiles,
  setSelectedFiles,
}) => {
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFiles(files) {
    files.forEach( (file) => {
      setFiles(prev => ([...prev, file]));
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
      setSelectedFiles(prev => ([...prev, file]));
    })
  }

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col>
          <FormGroup className="mb-3">
            <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
              Images
            </Label>
            {editing || !selectedProduct ? (
              <>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <Row>
                  {selectedFiles.map((f, i) => {
                    return (
                      <Col md={2} key={i + "-file"}>
                        <div className="p-2">
                          <Row
                            className="align-items-center"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Col className="col-auto">
                              <i
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let newSelectedFiles = [...selectedFiles];
                                  newSelectedFiles.splice(i, 1);
                                  setSelectedFiles(newSelectedFiles);
                                  let newFiles = [...files];
                                  newFiles.splice(i, 1);
                                  setFiles(newFiles);
                                }}
                                className="ion ion-md-close"
                              ></i>
                            </Col>
                          </Row>
                          <Row
                            className="align-items-center"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Col className="col-auto">
                              {f.type.includes("image") ? (
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              ) : (
                                f.name
                              )}
                            </Col>
                          </Row>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Row>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </>
            ) : (
              <div>
                <Row>
                  {selectedProduct.images.map((one, index) => (
                    <Col md={3} xs={6} key={`img${index}`}>
                      <img
                        src={one}
                        alt=""
                        style={{
                          maxWidth: "15vw",
                          maxHeight: "15vw",
                        }}
                      ></img>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Images;
