import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

const App = () => {
  useEffect(() => {
    console.log(window.location.pathname);
    if (window.location.pathname == "/") {
      if (sessionStorage.getItem("admin"))
        window.location.replace("/dashboard");
      else window.location.replace("/products");
    }
  }, []);
  return (
    <React.Fragment>
      <div>
        <Routes>
          <Route>
            {authRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>
          <Route>
            {userRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<Authmiddleware>{route.component}</Authmiddleware>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>
        </Routes>
      </div>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
